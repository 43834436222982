import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer: false,
    products: [],
    items: [
      { icon: "mdi-home-outline", text: "Książki", link: "home" },
      { icon: "mdi-information-outline", text: "O mnie", link: "about" },
      { icon: "mdi-sticker-alert-outline", text: "Regulamin", link: "rules" },
    ],
  },
  getters: {
    categories: state => {
      const categories = []
      return categories.sort().slice(0, 4)
    },
    links: (state, getters) => {
      return state.items.concat(getters.categories)
    },
    products: (state) => {
      return state.products;
    },
    product: (state) => (id) => {
      return state.products.find(product => product.koszykID === id);
    },
  },
  mutations: {
    setDrawer: (state, payload) => (state.drawer = payload),
    toggleDrawer: state => (state.drawer = !state.drawer),
  },
  actions: {

  },
})
