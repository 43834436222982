<template>
    <v-hover v-slot="{ hover }">
        <v-card tile v-bind="$attrs" hover v-on="$listeners" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }">
            <slot />
        </v-card>
    </v-hover>
</template>

<script>
export default {
    name: "BaseCard",
};
</script>
<style lang="scss" scoped>
.v-card {
    transition: opacity 0.2s ease-in-out;
    opacity: 1;
}

.v-card {
    &:not(.on-hover) {
        opacity: 0.95;
    }
}
</style>