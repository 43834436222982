<template>
	<h2 data-aos="zoom-in" class="subheading text-uppercase ma-10">
		<slot />
	</h2>
</template>

<script>
export default {
	name: "BaseSubheading",
};
</script>
<style lang="scss" scoped>
.subheading {
	margin: 1em 0 0.5em 0 !important;
	line-height: 40px !important;
	padding: 15px 15px 15px !important;
	color: #355681;
	box-shadow: 0 0 0 1px rgba(53, 86, 129, 0.705), inset 0 0 5px rgba(53, 86, 129, 0.856);
	border-radius: 0 10px 0 10px;
	background: url("../../assets/feather.png") no-repeat center right, url("../../assets/feather-right.png") no-repeat center left;
	background-size: contain;
	font-family: "GloriaHallelujah" !important;
	font-size: 30px !important;
	font-style: italic !important;
}
@media (max-width: 979px) {
	.subheading {
		background-size: 45px;
		margin-left: 0 !important;
		margin-right: 0 !important;
		font-size: 20px !important;
	}
}
</style>
