import Vue from 'vue'
import vuetify from './plugins/vuetify'
import './plugins/base'
import App from './App.vue'
import router from './router'
import store from './store'
import wb from './registerServiceWorker'
import vueMeta from "vue-meta";
import AOS from 'aos'
import 'aos/dist/aos.css';

Vue.config.productionTip = false
Vue.prototype.$workbox = wb;
Vue.use(vueMeta);

new Vue({
  vuetify,
  router,
  store,
  created() {
    AOS.init()
  },
  render: h => h(App),
}).$mount('#app')
