<template>
	<v-app style="overflow-x: hidden">
		<core-app-bar />
		<core-drawer />
		<v-snackbar top class="update-dialog" v-model="snackbar" :vertical="vertical" :timeout="timeout">
			Dostępna nowa wersja
			<template v-slot:action="{ attrs }">
				<v-btn @click="update">Aktualizuj</v-btn>
				<v-btn @click="snackbar = false">Anuluj</v-btn>
			</template>
		</v-snackbar>
		<core-view />
		<v-divider />
		<transition name="fade" appear>
			<core-footer />
		</transition>
	</v-app>
</template>

<script>
export default {
	name: "App",
	metaInfo() {
		return {
			meta: [
				{ property: "og:site_name", content: "Natasha Lucas" },
				{ property: "og:type", content: "website" },
			],
		};
	},
	data() {
		return {
			snackbar: false,
			vertical: true,
			timeout: 20000,
		};
	},
	components: {
		CoreDrawer: () => import("@/components/core/Drawer"),
		CoreFooter: () => import("@/components/core/Footer"),
		CoreAppBar: () => import("@/components/core/AppBar"),
		CoreView: () => import("@/components/core/View"),
	},
	created() {
		if (this.$workbox) {
			this.$workbox.addEventListener("waiting", () => {
				this.snackbar = true;
			});
		}
	},
	methods: {
		async update() {
			this.snackbar = false;
			await this.$workbox.messageSW({ type: "SKIP_WAITING" });
		},
	},
};
</script>
<style>
.update-dialog {
	z-index: -1;
	position: fixed;
}
@font-face {
	font-family: "GloriaHallelujah";
	src: local("GloriaHallelujah"), url(./assets/fonts/GloriaHallelujah-Regular.ttf) format("truetype");
}
@font-face {
	font-family: "GreatVibes";
	src: local("GreatVibes"), url(./assets/fonts/GreatVibes-Regular.otf) format("opentype");
}
#app {
	background: rgb(246, 249, 254); /* Old browsers */
	background: -moz-linear-gradient(top, rgba(246, 249, 254, 1) 5%, rgba(226, 240, 254, 1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(246, 249, 254, 1) 5%, rgba(226, 240, 254, 1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(246, 249, 254, 1) 5%, rgba(226, 240, 254, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f9fe', endColorstr='#e2f0fe',GradientType=0 ); /* IE6-9 */
}
</style>
