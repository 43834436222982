import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
const router = new Router({

  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
    },
    {
      path: '/o-mnie',
      name: 'about',
      component: () => import(/* webpackChunkName: "About" */ '../views/About.vue'),
    },
    {
      path: '/regulamin',
      name: 'rules',
      component: () => import(/* webpackChunkName: "Rules" */ '../views/Rules.vue'),
    },
  ],
})

export default router

